<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- project -->
      <template #cell(project)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.project }}
            </div>
          </div>
        </div>
      </template>

      <!-- productType -->
      <template #cell(productType)="data">
        <div class="d-flex align-items-center">
          <div class="d-grid">
            <span>{{ data.item.productType }}</span>
          </div>
        </div>
      </template>

      <!-- amount -->
      <template #cell(amount)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">
            {{ data.item.amount }}
          </span>
        </div>
      </template>

      <!-- budget -->
      <template #cell(budget)="data">
        {{ data.item.budget }}
      </template>

      <!-- budget -->
      <template #cell(actions)>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-multi-2
          variant="outline-primary"
        >
          نمایش پیشنهاد ها
        </b-button>
      </template>
    </b-table>
    <!-- modal -->
    <b-modal
      id="modal-multi-2"
      title="پیشنهاد ها"
      size="xl"
      ok-only
      scrollable
      ok-title="بستن"
    >
      <b-card-text class="my-2">
        <requests-table :table-data="data.RequestsTable" />
        <b-card-title>پیشنهاد های سیستمی</b-card-title>
        <proposals-system :table-data="datasys.ProposalsSystem" />
      </b-card-text>
    </b-modal>
    <!-- modal -->

    <!-- modal -->
    <b-modal
      id="modal-multi-3"
      size="sm"
      title="جزئیات"
      ok-only
      ok-title="بستن"
    >
      <proposals-details></proposals-details>
    </b-modal>
    <!-- modal -->
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BAvatar,
  BImg,
  BButton,
  BCollapse,
  BCardText,
  VBToggle,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import RequestsTable from "./RequestsTable.vue";
import ProposalsSystem from "./ProposalsSystem.vue";
import ProposalsDetails from "./proposalsDetails.vue";

export default {
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BCardTitle,
    BTable,
    BAvatar,
    BImg,
    BModal,
    BButton,
    BCollapse,
    BCardText,
    RequestsTable,
    ProposalsSystem,
    ProposalsDetails,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      data: {},
      datasys: {},
      fields: [
        { key: "project", label: "نام پروژه" },
        { key: "productType", label: "نوع محصول" },
        { key: "amount", label: "مقدار" },
        { key: "budget", label: "بودجه" },
        { key: "actions", label: "عملیات" },
      ],
    };
  },
  created() {
    // data
    this.$http.get("/requests/data").then((response) => {
      this.data = response.data;
    });
    this.$http.get("/proposals/system/data").then((response) => {
      this.datasys = response.data;
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
