<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table :items="tableData" responsive :fields="fields" class="mb-0">
      <!-- rank -->
      <template #cell(rank)="data">
        <div class="d-flex align-items-center">
          <b-avatar rounded size="32" variant="light-company mr-1">
            <b-img :src="data.item.ranksvg" alt="avatar img"
          /></b-avatar>
          <div>
            <div class="font-weight-bolder">
              {{ data.item.rank }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(productName)="data">
        <div>
          {{ data.item.productName }}
        </div>
      </template>
      <template #cell(productType)="data">
        <b-avatar class="mr-1" :variant="data.item.avatarColor">
          <feather-icon size="18" :icon="data.item.avatarIcon" />
        </b-avatar>
      </template>
      <template #cell(productBasic)="data">
        <div>
          {{ data.item.productBasic }}
        </div>
      </template>
      <template #cell(usage)="data">
        <div>
          {{ data.item.usage }}
        </div>
      </template>

      <!-- recommender -->
      <template #cell(recommender)="data">
        <div class="d-flex align-items-center">
          <b-avatar class="mr-1" :variant="data.item.avatarColor">
            <feather-icon size="18" :icon="data.item.avatarIcon" />
          </b-avatar>
          <div class="d-grid">
            <span>{{ data.item.title }}</span>
            <div class="font-small-2 text-muted">
              {{ data.item.subtitle }}
            </div>
          </div>
        </div>
      </template>

      <!-- totalprice -->
      <template #cell(totalprice)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">
            {{ data.item.totalprice }}
          </span>
        </div>
      </template>

      <!-- usersreview -->
      <template #cell(usersreview)="data">
        {{ data.item.usersreview }}
      </template>

      <!-- action -->
      <template #cell(actions)>
        <div class="d-flex">
          <!-- button  -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-multi-3
            variant="primary"
          >
            جزئیات
          </b-button>
          <b-button @click="goToHome()" variant="primary mx-1 px-1">
            انعقاد قرارداد
          </b-button>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BTable,
  BAvatar,
  BImg,
  BButton,
  BCollapse,
  BCardText,
  VBToggle,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import RequestsTable from "./RequestsTable.vue";

export default {
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BButton,
    BCollapse,
    BCardText,
    BModal,
    RequestsTable,
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      fields: [
        { key: "rank", label: "رتبه" },
        { key: "productName", label: "نام محصول" },
        { key: "productType", label: "نوع محصول" },
        { key: "companyName", label: "نام شرکت" },
        { key: "productBasic", label: "پایه محصول" },
        { key: "usage", label: "مصرف در متر مکعب" },
        { key: "recommender", label: "پیشنهاد دهنده" },
        { key: "totalprice", label: "قیمت کل" },
        { key: "usersreview", label: "رضایت کاربر" },
        { key: "actions", label: "عملیات" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
