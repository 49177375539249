<template>
  <!-- badge -->
  <b-list-group>
    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
    >
      <span>استفاده کل محصول</span>
      <b-badge variant="primary" pill class="badge-round"> 8 کلیوگرم</b-badge>
      <b-badge variant="primary" pill class="badge-round"> 800 تومان</b-badge>
    </b-list-group-item>

    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
    >
      <span>استفاده در آخرین پروژه</span>
      <b-badge variant="primary" pill class="badge-round"> 7 کلیوگرم </b-badge>
      <b-badge variant="primary" pill class="badge-round"> 700 تومان </b-badge>
    </b-list-group-item>

    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
    >
      <span>میزان رضایت از محصول</span>
      <b-badge variant="primary" pill class="badge-round"> 60 درصد </b-badge>
    </b-list-group-item>

    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
    >
      <span>تطابق محصول با ادعای فروشنده</span>
      <b-badge variant="primary" pill class="badge-round"> 60 درصد </b-badge>
    </b-list-group-item>

    <b-list-group-item
      class="d-flex justify-content-between align-items-center"
    >
      <span>امتیاز محصول</span>
      <b-badge variant="primary" pill class="badge-round">A</b-badge>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";

export default {
  components: {
    BBadge,
    BListGroup,
    BListGroupItem,
  },
};
</script>