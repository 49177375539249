<template>
  <section id="dashboard-ecommerce">
    <!-- <b-row class="match-height">
      <b-col xl="4" md="6">
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <b-col xl="8" md="6">
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <!-- Company Table Card -->
      <b-col cols="12">
        <b-card>
          <b-card-title>درخواست های شما</b-card-title>
          <proposals-table :table-data="dataT.ProposalsTable" />
        </b-card>
      </b-col>
      <!--/ Company Table Card -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import ProposalsTable from "./ProposalsTable.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    ProposalsTable,
  },
  data() {
    return {
      data: {},
      dataT: {},
    };
  },
  created() {
    // data
    this.$http.get("/requests/data").then((response) => {
      this.data = response.data;
    });
    this.$http.get("/proposals/data").then((response) => {
      this.dataT = response.data;
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
